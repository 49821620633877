.text::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: white;
    transition: 500ms;
}

.text:hover::after {
    opacity: 1;
}

@media screen and (max-width: 1040px) {
    .menuLinkForNav {
        min-width: 352px !important;
    }
}

.menuLinkForNav {
    margin-left: -800px;
    transition: none;
}

.menuLinkForNavOpen {
    margin-left: 0px;
    transition: margin 0.5s ease;
}