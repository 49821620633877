    /* On screens that are 600px or less */
    
    @media screen and (max-width: 768px) {
        .follow {
            display: none;
        }
        .contentWrapper {
            flex-direction: column;
            .contacts {
                padding-top: 100px;
                padding-bottom: 100px;
                padding: 44px 0 !important;
            }
        }
    }

    .menuComponent {
        height: 0vh;
        transition: height 1s ease;
        position: fixed;
        width: 100%;
    }

    .menuComponentOpen {
        z-index: 5;
        height: 100vh !important;
    }

    .rightSide {
        margin-right: -800px;
        transition: none;
    }

    .rightSideOpen {
        margin-right: 150px !important;
        transition: margin 0.5s ease;
    }