.grid {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 768px) {
    .grid {
        justify-content: center;
        align-items: center;
        gap: 56px;
        padding: 64px !important;
    }
}

@media screen and (max-width: 768px)  and (min-width: 490px) {
    .grid {
        justify-items: center;
        display: flex;
        gap: 24px;
        padding: 64px 24px !important;
    }
}

@media screen and (max-width: 490px) {
    .grid {
        justify-items: center;
        display: flex;
        grid-template-columns: 1fr !important;
        gap: 24px;
        padding: 48px 24px !important;
    }
}