.select {
  div {
    color: #121d33;
    border-radius: 24px !important;
    line-height: normal !important;
  }
}

@media screen and (max-width:"767px"){
  .select {
    div {
      max-width: 100%;
    }
  }
}
