.textStyles {
    h6 {
        //styleName: Heading 6;
        font-family: DM Sans;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.6399999856948853px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
    p {
        //styleName: Paragraph LG;
        font-family: DM Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.2px;
        padding-bottom: 32px;
    }
}

@media screen and (min-width: 1040px) {
    .wrapper {
        padding: 64px !important;
        flex-direction: row;
    }
}

@media screen and (max-width: 1040px) and (min-width: 489px) {
    .textStyles {
        width: 100% !important;
    }
    .imageWrapper {
        width: 100% !important;
    }
}

@media screen and (max-width: 1040px) and (min-width: 768px) {
    .wrapper {
        padding: 64px 32px !important;
        flex-direction: row;
    }
}

@media screen and (max-width: 768px) and (min-width: 489px) {
    .wrapper {
        padding: 64px 24px !important;
        flex-direction: column;
    }
    .imageWrapper {
        max-height: 720px !important;
    }
}

@media screen and (max-width: 489px) {
    .textStyles {
        margin: 0px !important;
        width: auto !important;
    }
    .wrapper {
        padding: 48px 24px !important;
        flex-direction: column !important;
        .imageWrapper {
            height: auto !important;
            width: auto !important;
        }
        .statWrapper {
            width: auto !important;
            padding: 55px 24px 55px 24px !important;
        }
    }
    .imageWrapper {
        max-height: 720px !important;
    }
}