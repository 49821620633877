.video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
//     opacity: 0.2;
//     -webkit-opacity: 0.2;
//     filter: alpha(opacity=20);
//     -webkit-filter: alpha(opacity=20);
}

// @supports (-webkit-touch-callout: none) {
//     .video {
//         height: -webkit-fill-available;
//     }
// }

@media screen and (max-width:1040px) {
    .headerWrapper {
        min-height: 600px !important;
    }
    .contectWrapper {
        height: 500px !important;
    }
    .headerText {
        font-size: 64px !important;
        line-height: 72px !important;
        letter-spacing: -1.2px !important;
        font-weight: 400;
    }
    .heroDesc {
        font-size: 24px !important;
        line-height: 36px !important;
        letter-spacing: -0.24px !important;
    }
}

.button
{
    .burger {
        margin-top: 4px;
        width: 24px;
        height: 2px;
        background-color: var(--chakra-colors-chakra-border-color);
        border-radius: 5px;
        transition: all .5s ease-in-out;

        &::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 2px;
            background-color: var(--chakra-colors-chakra-border-color);
            border-radius: 5px;
            transition: all .5s ease-in-out;
            transform: translateY(12px) translateX(-11.7px);
        }
    }

    &:hover {
        .burger {
            background-color: #121D33;
        }

        .burger::after {
            width: 24px;
            background-color: #121D33;
        }
    }
}
