@media screen and (max-width: 768px) {
    .text {
        width: 100% !important;
    }
}

@media screen and (max-width: 1040px) {
    .blockQuote {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 489px) {
    .textWrapper {
        /* margin: 0px 24px !important; */
    }
    .text:first-child {
        font-size: 32px !important;
        line-height: 40px !important;
    }
    .text :nth-child(0) {
        font-size: 24px !important;
        line-height: 36px !important;
        letter-spacing: -0.47999998927116394px !important;
    }
}
