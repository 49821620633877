.text::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: white;
    transition: 500ms;
}

.text:hover::after {
    opacity: 1;
}