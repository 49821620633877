@media screen and (max-width: 1440px) {
  .testimonialWrapper {
    margin: 32px !important;
  }
}
@media screen and (max-width: 1040px) {
  .testimonialWrapper {
    margin: 24px !important;
    padding: 48px 24px !important;
  }
}

@media screen and (max-width: 550px) {
  .blockquoteStyles {
    border-left: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 489px) {
  .blockquoteStyles {
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 400 !important;
  }
}
