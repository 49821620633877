.buttonMenu
{
  .burgerMenu {
    margin-top: 4px;
    width: 24px;
    height: 2px;
    background-color: #fff;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
      transition: all .5s ease-in-out;
      transform: translateY(12px) translateX(-11.7px);
    }
  }

  &:hover {
    .burger::after {
      width: 24px;
    }
  }

  &.open {
    .burgerMenu {
      transform: rotate(45deg) translate(2px, 6px);
    }

    .burgerMenu::after {
      width: 24px;
      transform: rotate(-90deg) translate(0px, -12px);
    }

    &:hover {
      .burgerMenu {
          background-color: #121D33;
      }

      .burgerMenu::after {
          background-color: #121D33;
      }
  }
  }
}