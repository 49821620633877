.imgBackground {
  background: linear-gradient(180deg, #121D33 40%, #FFF 40%) !important;
}

@media screen and (min-width: 1040px) and (max-width: 1090px) {
  .postHeaderImage {
    margin-top: -60px !important;
  }
}

@media screen and (max-width: 580px) {
  .postHeaderImage {
    margin-top: -60px !important;
  }
}

@media screen and (max-width: 1040px) {
  .image {
    width: 100% !important;
  } 
}