@media screen and (max-width: 1040px) {
  .cardsGrid {
    padding: 64px 24px !important;
    gap: 24px !important;
  }
}
@media screen and (max-width: 768px) {
  .cardsGrid {
    grid-template-columns: 1fr !important;
    gap: 52px !important;
  }
}
