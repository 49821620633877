.customCardsWrapper {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 128px 64px;
}

.learnMoreText {
    cursor: pointer;
}

.cardGridGlobal {
    flex-grow: 1;
}

// @media screen and (max-width: 1440px) {
//     .customCardsWrapper {
//         padding-right: 0px !important;
//     }
// }

@media screen and (max-width: 1320px) {
    .circle {
        display: none;
    }
}

@media screen and (max-width: 1040px) {
    .wrapper {
        margin: 64px 24px !important;
    }
    .contentWrapper {
        padding: 0px !important;
    }
}

@media screen and (max-width: 768px) {
    .heroText {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: -0.8px !important;
    }
    .customCardsWrapper {
        padding: 64px 0px 64px 24px !important;
    }
}

@media screen and (max-width: 489px) {
    .grid {
        grid-column-gap: 24px !important;
    }
}