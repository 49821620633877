@media screen and (max-width: 1040px) and (min-width: 768px) {
    .dropdownWrapper {
        padding: 24px 24px !important;
    }
    .buttonWrapper{
        padding: 24px 24px !important;
    }
}

@media screen and (max-width: 768px) {
    .dropdownWrapper {
        padding: 24px 24px !important;
    }
}

@media screen and (max-width: 872px) {
    .buttonWrapper{
        display: none !important;
    }
    .dropdownWrapper{
        display: block !important;
    }
}