@media screen and (max-width:1040px) {
    .wrapper{
        min-width: auto !important;
    }
}

@media screen and (max-width: 768px) {
    .wrapper{
        max-width: 768px !important;
    }
}