@media screen and (max-width: 768px) {
    .cardsGrid {
        grid-template-columns: 1fr !important;
    }
    .headerWrapper {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}

@media screen and (max-width:1440px) {
    .contentWrapper {
        padding: 64px 32px !important;
    }
}

@media screen and (max-width:1040px) {
    .contentWrapper {
        padding: 64px 24px !important;
    }
}

@media screen and (max-width:489px) {
    .contentWrapper {
        padding: 64px 24px !important;
    }
    .headerWrapper {
        flex-direction: column;
        align-items: baseline !important;
        padding: 0px 0px !important;
        button {
            padding: 32px 0 48px 0;
        }
    }
    .cardsGrid {
        grid-template-columns: 1fr !important;
        padding: 0px 0px !important;
    }
}