 @media screen and (max-width: 768px) { 
     .cardTitle { 
         font-size: 24px !important; 
         line-height: 36px; 
         letter-spacing: -0.48px; 
         padding-top: 24px !important;
}
}

/* @media screen and (max-width: 950px) { */
/*     .cardTitle { */
/*         font-size: 36px !important; */
/*         line-height: 36px; */
/*         letter-spacing: -0.48px; */
/*         padding-top: 24px !important; */
/*     } */
/* } */
