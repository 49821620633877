.paragraphText {
    margin-bottom: 20px;
}

.contentWrapper {
    max-width: 1440px;
    // width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.headerText {
    width: 66.67%;
    margin-left: 0px;
}

.centerHeader {
    width: 66.67%;
    margin-left: auto !important;
    margin-right: auto;
}

@media screen and (max-width: 900px) {
    .heroText {
        width: 100% !important;
    }
}

@media screen and (min-width: 1040px) {
    .contentWrapper {
        padding: 64px 64px 64px 64px !important;
    }
}

@media screen and (max-width: 1040px) {
    .headerText {
        width: 100%;
    }
}

@media screen and (max-width: 1040px) and (min-width: 768px) {
    .contentWrapper {
        padding: 64px 32px 64px 32px !important;
    }
    .buttonLink {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 768px) and (min-width: 490px) {
    .contentWrapper {
        padding: 64px 24px 64px 24px !important;
    }
    .buttonLink {
        margin-top: 20px !important;
    }
    .centerHeader {
        width: 100% !important;
    }
}

@media screen and (max-width: 490px) {
    .headerText {
        font-weight: 500 !important;
        font-size: 40px !important;
        line-height: 48px !important;
    }
    .textUnderTitle {
        margin-top: 32px !important;
        font-size: 20px !important;
    }
    .contentWrapper {
        padding: 64px 24px 64px 24px !important;
    }
}

.button {
    .burger {
        margin-top: 4px;
        width: 24px;
        height: 2px;
        background-color: var(--chakra-colors-chakra-border-color);
        border-radius: 5px;
        transition: all .5s ease-in-out;
        &::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 2px;
            background-color: var(--chakra-colors-chakra-border-color);
            border-radius: 5px;
            transition: all .5s ease-in-out;
            transform: translateY(12px) translateX(-11.7px);
        }
    }
    .darkBurger {
        background-color: #121D33 !important;
        &::after {
            background-color: #121D33 !important;
        }
    }
    &:hover {
        .burger {
            background-color: #121D33;
        }
        .burger::after {
            width: 24px;
            background-color: #121D33;
        }
        .darkBurger {
            background-color: var(--chakra-colors-chakra-border-color) !important;
            &::after {
                background-color: var(--chakra-colors-chakra-border-color) !important;
            }
        }
    }
}