.textStyles {
    h6 {
        //styleName: Heading 6;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.6399999856948853px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
    p {
        //styleName: Paragraph LG;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.23999999463558197px;
        padding-bottom: 32px;
    }
}

@media screen and (min-width: 1040px) {
    .wrapper {
        padding: 64px !important;
        gap: 56px;
    }
}

@media screen and (max-width: 1040px) {
    .wrapper {
        padding: 64px 24px !important;
        flex-direction: row;
        gap: 24px;
        .statLabel {
            font-size: 64px !important;
            line-height: 72px;
        }
    }
    .statBox {
        width: 100% !important;
        height: 600px !important;
        padding: 64px 0px !important;
    }
    .imageWrapper {
        height: auto !important;
        width: auto !important;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding: 64px 24px;
        flex-direction: column;
        gap: 24px;
    }
    .statBox {
        height: 500px !important
    }
    .imageWrapper {
        max-height: 720px !important;
    }
}

@media screen and (max-width: 489px) {
    .textStyles {
        margin: 0px 24px !important;
    }
    .statWrapper {
        padding: 0 24px !important;
    }
    .wrapper {
        gap: 24px !important;
        padding: 48px 24px !important;
        flex-direction: column !important;
    }
}