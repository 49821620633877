@media screen and (max-width: 489px) {
    .textStyles {
        line-height: 72px !important;
        padding-bottom: 32px !important;
    }
}

@media screen and (max-width: 1040px) {
    .description {
        max-width: 300px;
    }
}