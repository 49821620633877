.menuTab {
    cursor: pointer;
}

.buttonContainer {
    padding-bottom: 200px !important;
    margin-top: -50px;
}

.menuTabActive {
    color: #59D8C9 !important;
    cursor: pointer;
    transition: color 1s;
}

.menuTabSmall {
    cursor: pointer;
    opacity: 0.05;
}

.buttonLink {}

.menuTabRight {
    cursor: pointer;
    opacity: 0.05;
}

.menuTabActiveRight {
    cursor: pointer;
    transition: all 1s ease;
    opacity: 1;
}

.smallWrapper {
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width: 1040px) {
    .smallWrapper {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .buttonContainer {
        padding-left: 24px !important;
    }
}

@media screen and (max-width: 1196px) {
    .buttonContainer {
        padding-left: 102px;
        padding-bottom: 200px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 490px) {
    .menuTabSmall {
        font-size: 48px !important;
        line-height: 56px !important;
        letter-spacing: -0.96px !important;
    }
    .menuTabActive {
        font-size: 48px !important;
        line-height: 56px !important;
        letter-spacing: -0.96px !important;
    }
}

@media screen and (max-width: 490px) {
    .buttonContainer {
        padding-left: 102px;
        padding-bottom: 200px;
        margin-top: -50px;
    }
    .menuTabSmall {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: -0.8px !important;
    }
    .menuTabActive {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: -0.8px !important;
    }
    .menuTabRight {
        font-size: 24px !important;
        line-height: 36px !important;
        letter-spacing: -0.48px !important;
    }
    
    .menuTabActiveRight {
        font-size: 24px !important;
        line-height: 36px !important;
        letter-spacing: -0.48px !important;
    }
}