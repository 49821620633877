.text::after {
	content: '';
	opacity: 0;
	height: 2px;
	display: block;
	background: white;
	transition: 500ms;
}

.text:hover::after {
	opacity: 1;
}

@media screen and (max-width: 1023px) {
	.headerWrapper {
		padding: 24px 40px !important;
	}
}

@media screen and (max-width: 900px) {
	.heroText {
		width: 100% !important;
	}
	.contentWrapper {
		padding-left: 0px !important;
	}
}

@media screen and (max-width: 680px) {
	.heroText {
		font-size: 50px !important;
	}
}

@media screen and (max-width: 489px) {
	.heroText {
		//styleName: Heading 5;
		font-size: 40px !important;
		line-height: 48px !important;
		letter-spacing: -0.800000011920929px !important;
	}
	.contentWrapper {
		padding-top: 84px !important;
		padding-left: 0px !important;
	}
}

.button {
	.burger {
		margin-top: 4px;
		width: 24px;
		height: 2px;
		background-color: var(--chakra-colors-chakra-border-color);
		border-radius: 5px;
		transition: all 0.5s ease-in-out;
		&::after {
			content: '';
			position: absolute;
			width: 15px;
			height: 2px;
			background-color: var(--chakra-colors-chakra-border-color);
			border-radius: 5px;
			transition: all 0.5s ease-in-out;
			transform: translateY(12px) translateX(-11.7px);
		}
	}
	&:hover {
		.burger {
			background-color: #121d33;
		}
		.burger::after {
			width: 24px;
			background-color: #121d33;
		}
	}
}
