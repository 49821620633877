.circle {
  transform: scale(15);
}

@media screen and (max-width: 1440px) {
  .wrapper {
    margin: 64px 32px !important;
  }
}
@media screen and (max-width: 1320px) {
  .circle {
    display: none;
  }
}
@media screen and (max-width: 1040px) {
  .wrapper {
    margin: 64px 24px !important;
  }
  .contentWrapper{
    padding :0px !important;
  }
}
@media screen and (max-width: 768px) {
  .heroText{
    font-size: 40px !important;
    line-height: 48px !important;
    letter-spacing: -0.8px !important;

  }
}
