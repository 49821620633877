.highlightWrapper {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1040px) {
  .highlightWrapper {
    padding: 128px 178px;
  }
  .grid {
    row-gap: 96px !important;
    column-gap: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1040px) {
  .highlightWrapper {
    padding: 96px 24px;
  }
  .grid {
    justify-content: center !important;
    row-gap: 96px !important;
    column-gap: 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .highlightWrapper {
    padding: 64px 24px;
  }
  .grid {
    grid-template-columns: 1fr !important;
    row-gap: 48px !important;
  }
}
