.centerHeader {
    width: 66.67%;
    margin-left: auto !important;
    margin-right: auto;
}

.calendlyGrid {
    height: 368px;
    bottom: 12.9%;
    border-radius: 32px;
    gap: 6%;
}

@media screen and (max-width:1010px) {
    .contactGrid {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    
    .oneCard {
        margin-bottom: 48px;
    }

    .backgroundElipse {
        display: none !important;
    }

    .backgroundGrey {
        display: none !important;
    }

    .centerHeader {
        width: 100% !important;
    }

    .calendlyGrid {
        flex-direction: column !important;
        height: auto !important;
        background: #121D33 !important;
        padding: 64px 48px !important;
    }

    .banerHeroText {
        color: #ffffff !important;
    }
    
    .banerLeft {
        width: 100% !important;
        padding-bottom: 48px;
    }
    
    .banerRight {
        width: 100% !important;
    }
}

@media screen and (max-width:794px) {
    .calendlyGrid {
        height: 778px;
    }
}

.button
{
    border-color: #121D33 !important;
    .burger {
        margin-top: 4px;
        width: 24px;
        height: 2px;
        background-color: #121D33;
        border-radius: 5px;
        transition: all .5s ease-in-out;
        
        &::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 2px;
            background-color: #121D33;
            border-radius: 5px;
            transition: all .5s ease-in-out;
            transform: translateY(12px) translateX(-11.7px);
        }
    }

    &:hover {
        .burger {
            background-color: #121D33 !important;
        }

        .burger::after {
            width: 24px;
            background-color: #121D33 !important;
        }
    }
}