/** @format */

.wysiwigWrapper {
	max-width: 1440px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 44px 64px;
	h3 {
		color: '#121D33';
		font-size: 40px;
		font-family: DM Sans;
		font-weight: 500;
		line-height: 40px;
		word-wrap: 'break-word';
		padding-top: 32px;
		padding-bottom: 32px;
		letter-spacing: -0.8px;
	}
	h4 {
		color: '#121D33';
		font-size: 32px;
		font-family: DM Sans;
		font-weight: 500;
		line-height: 40px;
		word-wrap: 'break-word';
		padding-top: 32px;
		padding-bottom: 32px;
		letter-spacing: -0.64px;
	}
	h5 {
		color: '#121D33';
		font-size: 24px;
		font-family: DM Sans;
		font-weight: 500;
		line-height: 40px;
		word-wrap: 'break-word';
		padding-top: 32px;
		padding-bottom: 32px;
		letter-spacing: -0.48px;
	}
	h6 {
		//styleName: Heading 6;
		font-family: DM Sans;
		font-size: 20px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: -0.6399999856948853px;
		padding-top: 32px;
		padding-bottom: 32px;
		letter-spacing: -0.4px;
	}
	p {
		//styleName: Paragraph LG;
		font-family: DM Sans;
		font-size: 20px;
		font-weight: 400;
		line-height: 36px;
		letter-spacing: -0.23999999463558197px;
		padding-bottom: 32px;
	}

	ul,
	ol {
		font-family: DM Sans;
		font-size: 20px;
		font-weight: 400;
		line-height: 36px;
		letter-spacing: -0.23999999463558197px;
		list-style-position: inside;
	}
	li {
		font-family: DM Sans;
		font-size: 20px;
		font-weight: 400;
		line-height: 36px;
		letter-spacing: -0.23999999463558197px;
		a {
			text-decoration: none;
		}
		&::marker {
			margin-right: 20px;
		}
	}
	a {
		// text-decoration: underline !important;
	}
}

@media screen and (max-width: 768px) {
	.textStyles {
		width: 100% !important;
	}
	.wysiwigWrapper {
		padding: 24px !important;
	}
}

@media screen and (max-width: 489px) {
	.wysiwigWrapper {
		padding-bottom: 48px !important;
		p {
			font-size: 20px !important;
			line-height: 32px !important;
			letter-spacing: -0.2px;
		}
		h6 {
			font-size: 32px !important;
			line-height: 40px !important;
		}
	}
}
