.servicesGroupWrapper {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 86px 64px;
}

@media screen and (max-width: 1040px) {
    .servicesGroupWrapper {
        gap: 24px !important;
        padding: 130px 24px;
    }
}

@media screen and (max-width: 768px) {
    .sectionFlex {
        background: #121D33 !important;
        /* height: 1200px !important; */
    }
    .servicesGroupWrapper {
        padding-top: 64px;
        padding-bottom: 64px;
        flex-direction: column;
        gap: 68px !important;
        justify-content: start !important;
        align-items: start !important;
    }
}

@media screen and (min-width: 769px) {
    .servicesImage {
        max-width: 628px;
        max-height: 628px;
    }
}
