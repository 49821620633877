.test {
    transition: all 0.3s ease-in-out;
    animation: moveitback 400ms ease forwards;
    will-change: transform;
}

.test:hover {
    animation: moveit 400ms ease forwards;
    transition: 0.3s;
}

.testBack {
    transition: all 0.3s ease-in-out;
    animation: moveit 400ms ease forwards;
    will-change: transform;
}

.testBack:hover {
    animation: moveitback 400ms ease forwards;
    transition: 0.3s;
}

.test::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: white;
    transition: 500ms;
}

.test:hover::after {
    opacity: 1;
}

.text::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: black;
    transition: 500ms;
}

.text:hover::after {
    opacity: 1;
}

.textTwo::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: white;
    transition: 500ms;
}

.textTwo:hover::after {
    opacity: 1;
}

@keyframes moveit {
    0% {
        transform: translateX(-0px);
    }
    50% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(-7px);
    }
    100% {
        transform: translateX(-10px);
    }
}

@keyframes moveitback {
    0% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0px);
    }
}