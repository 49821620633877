.contentWrapper {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 178px;
    padding-right: 178px;
}

.text::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: white;
    transition: 500ms;
}

.text:hover::after {
    opacity: 1;
}


/*Tablets*/
@media screen and (max-width: 985px) {
    .footerWrapper {
        padding-left: 64px !important;
        padding-right: 64px !important;
    }

    .contentWrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: "1180px") {
    .rightSide {
        width: 45% !important;
    }
    .copyright {
        width: 45% !important;
    }
}

@media screen and (max-width: 768px) {
    .footerWrapper {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .mainContentWrapper {
        flex-direction: column !important;
    }
    .policies {
        padding-top: 96px !important;
    }
    .contacts {
        display: none;
    }
    .copyright {
        width: auto !important;
    }
}


/*Phones*/

@media screen and (max-width: 489px) {
    .footerWrapper {
        padding-right: 24px !important;
    }
    .copyright {
        text-align: right !important;
        width: fit-content !important;
    }
}