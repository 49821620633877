@media screen and (max-width: "1180px") {
    .ctaContentWrapper {
        padding: 48px 64px !important;
        .heroText {
            //styleName: Heading 4;
            width: 45%;
            font-size: 48px;
            font-weight: 500;
            line-height: 56px;
            letter-spacing: -0.9599999785423279px;
            margin-right: 20px;
        }
        .sentenceWrapper {
            width: 45%;
            padding-top: 32px;
            padding-bottom: 0px;
        }
        .sentenceText {
            //styleName: Paragraph MD;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.20000000298023224px;
        }
    }
}

@media screen and (max-width: "768px") {
    .ctaContainer {
        height: auto !important;
    }
    .ctaContentWrapper {
        padding: 48px 64px !important;
        flex-direction: column !important;
        width: 100%;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        .heroText {
            //styleName: Heading 4;
            width: auto;
            font-size: 48px;
            font-weight: 500;
            line-height: 56px;
            letter-spacing: -0.9599999785423279px;
            margin-right: 20px;
        }
        .sentenceWrapper {
            width: auto;
            padding-top: 32px;
            padding-bottom: 0px;
        }
        .sentenceText {
            //styleName: Paragraph MD;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.20000000298023224px;
        }
    }
}


/*Phones*/

@media screen and (max-width: "489px") {
    .ctaContainer {
        height: auto !important;
    }
    .ctaContentWrapper {
        padding: 48px 24px !important;
        flex-direction: column !important;
        .heroText {
            //styleName: Heading 6;
            width: 100%;
            font-family: DM Sans;
            font-size: 32px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: -0.6399999856948853px;
        }
        .sentenceWrapper {
            width: auto;
            padding-top: 32px;
            padding-bottom: 0px;
        }
        .sentenceText {
            //styleName: Paragraph MD;
            width: 100%;
            font-family: DM Sans;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.20000000298023224px;
        }
    }
}