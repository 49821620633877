@media screen and (max-width: 900px) {
  .statsGridWrapper {
    flex-wrap: wrap;
    align-items: flex-start !important;
    gap: 64px !important;
  }
  .statWrapper {
    width: fit-content !important;
  }
}

@media screen and (max-width: 900px) {
  .statWrapper {
    width: 320px !important;
  }
}

@media screen and (min-width:1040px) {
  .statsGridWrapper {
      padding: 44px 114px !important;
  }
}
