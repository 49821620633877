@keyframes bounce {
    0% {
		-webkit-transform: translateX(0);
		-ms-transform:     translateX(0);
		transform:         translateX(0);
	}

    60% {
		-webkit-transform: translateX(-5px);
		-ms-transform:     translateX(-5px);
		transform:         translateX(-5px);
	}

    100% {
		-webkit-transform: translateX(0px);
		-ms-transform:     translateX(0px);
		transform:         translateX(0px);
	}
}

@keyframes unbounce {
    0% {
		-webkit-transform: translateX(0);
		-ms-transform:     translateX(0);
		transform:         translateX(0);
	}

    60% {
		-webkit-transform: translateX(5px);
		-ms-transform:     translateX(5px);
		transform:         translateX(5px);
	}

    100% {
		-webkit-transform: translateX(0px);
		-ms-transform:     translateX(0px);
		transform:         translateX(0px);
	}
}

.locationTitle {
    padding-bottom: 64px;
}

.text::after {
    content: "";
    opacity: 0;
    height: 2px;
    display: block;
    background: var(--chakra-colors-gray-800);
    transition: 500ms;
}

.text:hover::after {
    opacity: 1;
}

.locationsWrapper {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 128px 64px;
}

.locationImage {
    max-width: 628px !important
}

.direction {
    margin-top: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;

    .arrow {
        animation: all 0.3s ease-in-out;
        animation-name: unbounce;
        -moz-animation-name: unbounce;
    }
}

.direction:hover {
    .arrow {
        margin-left: 15px;
        animation-name: bounce;
        -moz-animation-name: bounce;
    }
}

@media screen and (max-width:1040px) and (min-width:768px) {
    .locationCards {
        gap: 24px !important;
    }
}

@media screen and (max-width:768px) {
    .locationTitle {
        padding-bottom: 32px !important;
    }

    .locationsWrapper {
        padding: 128px 24px !important;
    }

    .locationCards {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}